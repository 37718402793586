import request from '@/utils/request'


// 查询拼团活动商品列表
export function listGroupActivityProd(query) {
  return request({
    url: '/groupActivityProd/groupActivityProd/list',
    method: 'get',
    params: query
  })
}

// 查询拼团活动商品分页
export function pageGroupActivityProd(query) {
  return request({
    url: '/groupActivityProd/groupActivityProd/page',
    method: 'get',
    params: query
  })
}

// 查询拼团活动商品详细
export function getGroupActivityProd(data) {
  return request({
    url: '/groupActivityProd/groupActivityProd/detail',
    method: 'get',
    params: data
  })
}

// 新增拼团活动商品
export function addGroupActivityProd(data) {
  return request({
    url: '/groupActivityProd/groupActivityProd/add',
    method: 'post',
    data: data
  })
}

// 修改拼团活动商品
export function updateGroupActivityProd(data) {
  return request({
    url: '/groupActivityProd/groupActivityProd/edit',
    method: 'post',
    data: data
  })
}

// 删除拼团活动商品
export function delGroupActivityProd(data) {
  return request({
    url: '/groupActivityProd/groupActivityProd/delete',
    method: 'post',
    data: data
  })
}

// 审核拼团活动商品
export function auditGroupActivityProd(data) {
  return request({
    url: '/groupActivityProd/groupActivityProd/audit',
    method: 'post',
    data: data
  })
}
